<section>
  <app-header></app-header>
  <div class="frame">
    <nav>
      <app-nav-menu></app-nav-menu>
    </nav>
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</section>