import { DictionaryItem } from './dictionary-item';

export enum DictionaryType {
  LEVEL = 'LEVEL',
  COMPETENCE = 'COMPETENCE',
  DIRECTION = 'DIRECTION',
  DEPARTMENT = 'DEPARTMENT',
  LOCATION = 'LOCATION',
}

export interface DictionaryResolveData {
  dictionaries: string[];
  dictionaryInstances?: [string, DictionaryItem[]][];
}
