import { Pipe, PipeTransform } from '@angular/core';
import { DictionaryService } from './dictionary.service';
import { DictionaryItem } from '@shared/dictionary/dictionary-item';

@Pipe({
  name: 'appDictionary',
  standalone: true,
})
export class DictionaryPipe implements PipeTransform {
  public constructor(private readonly dictionaryService: DictionaryService) {}

  public transform(
    value: string,
    dictionaryName: string,
    property: keyof DictionaryItem = 'id',
  ): string {
    return this.dictionaryService.getLabelById(dictionaryName, value, property) ?? value;
  }
}
