import { importProvidersFrom } from '@angular/core';
import { IconDefinition } from '@ant-design/icons-angular';
import { Calendar, Download, Filter, Global, Home, Key, Phone, Printer, Send, Wifi } from '@shared/icon';
import { NzIconModule } from 'ng-zorro-antd/icon';

export const icons: IconDefinition[] = [
  Home,
  Phone,
  Calendar,
  Filter,
  Download,
  Send,
  Printer,
  Global,
  Key,
  Wifi,
];

export const IconProvider = importProvidersFrom(NzIconModule.forRoot(icons));