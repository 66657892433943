import { IconDefinition } from '@ant-design/icons-angular';

export const Download: IconDefinition = {
  name: 'download',
  theme: 'outline',
  icon: `<svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.94 8.9C20.54 9.21 22.01 11.06 22.01 15.11V15.24C22.01 19.71 20.22 21.5 15.75 21.5H9.23998C4.76998 21.5 2.97998 19.71 2.97998 15.24V15.11C2.97998 11.09 4.42998 9.24 7.96998 8.91" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.5 2V14.88" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
  <path d="M15.8499 12.65L12.4999 16L9.1499 12.65" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
  </svg>
  `,
};
