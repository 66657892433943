import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

type Config = {
  baseUrl: string;
};

@Injectable({ providedIn: 'root' })
export class ConfigService {
  public get baseUrl(): string {
    return this.config.baseUrl;
  }

  public get baseUrlWithPostfix(): string {
    return `${this.baseUrl}/v1`;
  }

  private config!: Config;

  private configUrl = 'assets/config.json';

  public constructor(private httpClient: HttpClient) {}

  public load(): Observable<Config> {
    return this.httpClient.get<Config>(this.configUrl).pipe(
      tap((config: Config) => {
        this.config = config;
      }),
    );
  }
}
