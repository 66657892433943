import { IconDefinition } from '@ant-design/icons-angular';

export const Filter: IconDefinition = {
  name: 'filter',
  theme: 'fill',
  icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="currentColor">
    <path d="M1.5 1.25H15" stroke="currentColor" stroke-width="1.125" stroke-linecap="round" fill="none"/>
    <path d="M3.75 5H12.75" stroke="currentColor" stroke-width="1.125" stroke-linecap="round" fill="none"/>
    <path d="M6 8.75H10.5" stroke="currentColor" stroke-width="1.125" stroke-linecap="round" fill="none"/>
  </svg>`,
};


