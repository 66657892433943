import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@app/services/config.service';
import { DictionaryItem } from '@shared/dictionary/dictionary-item';
import { Observable, of, zip } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

type DictionariesResponse = {
  items: DictionaryItem[];
};

@Injectable({ providedIn: 'root' })
export class DictionaryService {
  private static listOfNames(names: string | string[]): string[] {
    return Array.isArray(names) ? names : names.split(',');
  }

  public entries = new Map<string, DictionaryItem[]>();

  public url = '/dictionary';

  public constructor(
    private readonly _http: HttpClient,
    private readonly _config: ConfigService,
  ) {}

  public get(names: string | string[]): Observable<(DictionaryItem[])[]> {
    const nameList = DictionaryService.listOfNames(names);
    const dictionaries$ = nameList.map(name =>
      this.entries.has(name)
        ? of(this.entries.get(name) as DictionaryItem[])
        : this._http
          .get<DictionariesResponse>(
          `${this._config.baseUrlWithPostfix}${this.url}`,
          {
            params: {
              id: name,
            },
          },
        )
          .pipe(
            map((response: DictionariesResponse) => response.items),
            tap((response: DictionaryItem[]) => this.entries.set(name, response)),
            catchError(() => of([])),
          ),
    );
    return zip(...dictionaries$);
  }

  public instant(name: string): DictionaryItem[] {
    return this.instants(name).get(name) ?? [];
  }

  public instants(names: string | string[]): Map<string, DictionaryItem[]> {
    const nameList = DictionaryService.listOfNames(names);
    const filteredEntries = new Map<string, DictionaryItem[]>();
    this.entries.forEach((value, key) => nameList.includes(key) && filteredEntries.set(key, value));
    return filteredEntries;
  }

  public getItem(name: string, id: string): Undef<DictionaryItem> {
    return this.instant(name)?.find((item: DictionaryItem) => item.id === id);
  }

  public getLabelById(name: string, id: string, property: keyof DictionaryItem = 'id'): string {
    return this.getItem(name, id)?.[property] ?? id;
  }
}
