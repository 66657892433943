import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app/app.component';
import { provideRouter } from '@angular/router';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { APP_ROUTES } from './app/app/config/routes';
import { environment } from '@environment/environment.development';
import { NZ_DATE_CONFIG, NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';
import '@angular/common/locales/global/ru';
import { IconProvider } from '@app/config/provider/icon.provider';
import { AppInitializerProvider } from '@app/config/provider/app-initalizer.provider';
import { appReducer } from '@app/state/app.state';
import * as appEffects from '@app/state/app.effects';
import { tokenInterceptor } from '@app/interceptors/token.interceptor';

if (environment.production) {
  enableProdMode();
}

void bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES),
    importProvidersFrom(HttpClientModule, BrowserAnimationsModule),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    AppInitializerProvider,
    provideStore({ app: appReducer }),
    provideEffects(appEffects),
    IconProvider,
    provideRouterStore(),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    { provide: NZ_I18N, useValue: ru_RU },
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
  ],
});
