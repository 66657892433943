import { Routes } from '@angular/router';
import { DictionaryType, dictionaryResolver } from '@shared/dictionary';
import { MainLayoutComponent } from 'src/app/layouts/main/main.component';
import { authCanActivate } from '@app/guards/auth.guard';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('@authorization/routes').then(m => m.AUTH_ROUTES),
  },
  {
    path: 'app',
    component: MainLayoutComponent,
    canActivate: [authCanActivate],
    children: [
      {
        path: '',
        redirectTo: 'workspace',
        pathMatch: 'full',
      },
      {
        path: 'workspace',
        loadChildren: () => import('@workspace/routes').then(m => m.WORKSPACE_ROUTES),
      },
      {
        path: 'documents',
        loadChildren: () => import('@documents/routes').then(m => m.DOCUMENTS_ROUTES),
      },
      {
        path: 'employees',
        loadChildren: () => import('@employees/routes').then(m => m.EMPLOYEES_ROUTES),
        resolve: {
          dictionaries: dictionaryResolver,
        },
        data: {
          dictionaries: [
            DictionaryType.COMPETENCE,
            DictionaryType.LEVEL,
            DictionaryType.DEPARTMENT,
            DictionaryType.DIRECTION,
            DictionaryType.LOCATION,
          ],
        },
      },
      {
        path: 'projects',
        loadChildren: () => import('@projects/routes').then(m => m.PROJECTS_ROUTES),
      },
      {
        path: 'study-center',
        loadChildren: () => import('@study-center/routes').then(m => m.STUDY_CENTER_ROUTES),
      },
      {
        path: 'faq',
        loadChildren: () => import('@faq/routes').then(m => m.FAQ_ROUTES),
      },
    ],
  },
];
