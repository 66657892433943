import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Employee } from '@shared/model/employee';
import { TokenInfo } from '@shared/model/token-info';

export const appUserActions = createActionGroup({
  source: 'App User',
  events: {
    'Login Success': props<{ tokenInfo: TokenInfo }>(),
    'Login Failure': props<{ error: string }>(),
    refresh: emptyProps(),
    updateTokenInfo: props<{ tokenInfo: TokenInfo }>(),
    'Load User Success': props<{ user: Employee }>(),
    'Load User Failure': props<{ error: string }>(),
    logout: emptyProps(),
  },
});