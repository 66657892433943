import { inject, Injectable } from '@angular/core';
import { Route } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DictionaryService } from './dictionary.service';
import { DictionaryItem } from '@shared/dictionary/dictionary-item';
import { DictionaryResolveData } from './dictionary.interface';

@Injectable({ providedIn: 'root' })
export class DictionaryResolverService {
  public constructor(private readonly dictionaryService: DictionaryService) {}

  public resolve(route: Route): Observable<Map<string, DictionaryItem[]>> {
    const data = route.data as DictionaryResolveData;
    const { dictionaries } = data;
    return this.dictionaryService
      .get(dictionaries)
      .pipe(map(() => this.dictionaryService.instants(dictionaries)));
  }
}

export const dictionaryResolver =
  (route: Route): Observable<Map<string, DictionaryItem[]>> => inject(DictionaryResolverService).resolve(route);
