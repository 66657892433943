import { IconDefinition } from '@ant-design/icons-angular';

export const Global: IconDefinition = {
  name: 'global',
  theme: 'outline',
  icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="currentColor">
  <path d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.49961 3H9.49961C7.54961 8.84 7.54961 15.16 9.49961 21H8.49961" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.5 3C17.45 8.84 17.45 15.16 15.5 21" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.5 16V15C9.34 16.95 15.66 16.95 21.5 15V16" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.5 9C9.34 7.05 15.66 7.05 21.5 9" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
};
