import { selectCurrentUser } from './../state/app.selectors';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, mergeMap, of } from 'rxjs';

export const authCanActivate: CanActivateFn = (_route, _state) => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(selectCurrentUser).pipe(
    first(),
    mergeMap(user => {
      if (!user) {
        void router.navigate(['/auth']);
        return of(false);
      }
      return of(true);
    }),
  );
};