import { inject } from '@angular/core';
import { UserService } from '@app/services/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { appUserActions } from './actions/app-user.actions';
import { catchError, defer, first, map, mergeMap, of, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectTokenInfo } from './app.selectors';
import { TokenInfo } from '@shared/model/token-info';

export const refreshEffect = createEffect(
  (
    actions$ = inject(Actions),
    userService = inject(UserService),
    store = inject(Store),
  ) => actions$.pipe(
    ofType(appUserActions.refresh),
    mergeMap(() => store.select(selectTokenInfo).pipe(first())),
    mergeMap((tokenInfo: TokenInfo | null) => defer(() => {
      if (tokenInfo?.refresh_token) {
        return userService.refresh(tokenInfo.refresh_token).pipe(
          map(newTokenInfo => appUserActions.loginSuccess({ tokenInfo: newTokenInfo })),
          catchError(error => of(appUserActions.loginFailure({ error }))),
        );
      }
      return of(appUserActions.loginFailure({ error: 'Refresh token not found' }));
    })),
  ),
  { functional: true },
);

export const saveTokenInfoInLocalStorageEffect = createEffect(
  (
    actions$ = inject(Actions),
    userService = inject(UserService),
  ) => actions$.pipe(
    ofType(appUserActions.loginSuccess),
    tap(action => {
      userService.saveInLocalStorage(action.tokenInfo);
      userService.setRefreshSchedule(action.tokenInfo);
    }),
  ),
  { functional: true, dispatch: false },
);

export const getUserEffect = createEffect(
  (
    actions$ = inject(Actions),
    userService = inject(UserService),
  ) => actions$.pipe(
    ofType(appUserActions.loginSuccess),
    mergeMap(action => userService.getUserById(action.tokenInfo.user_id).pipe(
      map(user => appUserActions.loadUserSuccess({ user })),
      catchError(error => of(appUserActions.loadUserFailure({ error }))),
    )),
  ),
  { functional: true },
);

export const logoutEffect = createEffect(
  (
    actions$ = inject(Actions),
    userService = inject(UserService),
  ) => actions$.pipe(
    ofType(appUserActions.logout),
    tap(() => {
      userService.clearLocalStorage();
      userService.clearRefreshSchedule();
    }),
  ),
  { functional: true, dispatch: false },
);