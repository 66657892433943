import { IconDefinition } from '@ant-design/icons-angular';

export const Wifi: IconDefinition = {
  name: 'wifi',
  theme: 'outline',
  icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="currentColor">
  <path d="M5.41016 11.84C9.71016 8.52001 15.3002 8.52001 19.6002 11.84" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.5 8.35998C8.56 3.67998 16.44 3.67998 22.5 8.35998" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.28906 15.49C10.4391 13.05 14.5491 13.05 17.6991 15.49" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.90039 19.15C11.4804 17.93 13.5304 17.93 15.1104 19.15" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
};