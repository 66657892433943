import { IconDefinition } from '@ant-design/icons-angular';

export const Key: IconDefinition = {
  name: 'key',
  theme: 'outline',
  icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
  <path d="M19.7906 14.93C17.7306 16.98 14.7806 17.61 12.1906 16.8L7.48063 21.5C7.14063 21.85 6.47063 22.06 5.99063 21.99L3.81063 21.69C3.09063 21.59 2.42063 20.91 2.31063 20.19L2.01063 18.01C1.94063 17.53 2.17063 16.86 2.50063 16.52L7.20063 11.82C6.40063 9.22001 7.02063 6.27001 9.08063 4.22001C12.0306 1.27001 16.8206 1.27001 19.7806 4.22001C22.7406 7.17001 22.7406 11.98 19.7906 14.93Z" stroke="currentColor" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.89062 17.49L9.19062 19.79" stroke="currentColor" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z" stroke="currentColor" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
};